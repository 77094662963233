.medium-location {
    background-color: var(--secondary-primary-white);
    border-radius: 20px;
    height: 362px;
    overflow: hidden;
    position: relative;
    width: relative;
  }
  
  .medium-location .group {
    height: 24px;
    left: 987px;
    position: absolute;
    top: 321px;
    width: relative;
  }
  
  .medium-location .bid {
    color: #202020;
    font-family: "DM Sans-Bold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    left: 204px;
    letter-spacing: -0.28px;
    line-height: 24px;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
  }
  
  .medium-location .text-wrapper {
    color: #202020;
    font-family: "DM Sans-Bold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    left: 0;
    letter-spacing: -0.28px;
    line-height: 24px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .medium-location .div {
    color: #202020;
    font-family: "DM Sans-Bold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    left: 443px;
    letter-spacing: -0.28px;
    line-height: 24px;
    position: absolute;
    text-align: right;
    top: 0;
    white-space: nowrap;
  }
  
  .medium-location .text {
    height: 20px;
    left: 172px;
    position: absolute;
    top: 328px;
    width: 52px;
  }
  
  .medium-location .tag {
    color: #05cd99;
    font-family: "DM Sans-Bold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    left: 0;
    letter-spacing: -0.28px;
    line-height: 20px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .medium-location .photo {
    height: 303px;
    left: 45px;
    position: absolute;
    top: 0;
    width: 1400px;
  }
  
  .medium-location .overlap-group {
    height: 309px;
    width: relative;
    position: relative;
  }
  
  .medium-location .card {
    height: 253px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1500px;
  }
  
  .medium-location .ellipse {
    height: 221px;
    left: 42px;
    object-fit: cover;
    position: absolute;
    top: 88px;
    width: 221px;
  }
  
  .large-check-table {
    background-color: #ffffff;
    border-radius: 20px;
    height: 488px;
    overflow: hidden;
    position: relative;
    width: 782px;
  }
  
  .large-check-table .title {
    height: 32px;
    left: 22px;
    position: absolute;
    top: 23px;
    width: 248px;
  }
  
  .large-check-table .text-wrapper {
    color: #202020;
    font-family: "DM Sans-Bold", Helvetica;
    font-size: 24px;
    font-weight: 700;
    height: 32px;
    left: 0;
    letter-spacing: -0.48px;
    line-height: 32px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .large-check-table .cards {
    height: 340px;
    left: 22px;
    position: absolute;
    top: 102px;
    width: 734px;
  }
  
  .large-check-table .card {
    background-color: #f5f5f5;
    border-radius: 16px;
    height: 70px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 734px;
  }
  
  .large-check-table .ellipse {
    background-color: #e6e6e7;
    border-radius: 23px;
    height: 46px;
    left: 16px;
    position: absolute;
    top: 12px;
    width: 46px;
  }
  
  .large-check-table .overlap-group {
    height: 45px;
    left: 80px;
    position: absolute;
    top: 11px;
    width: 167px;
  }
  
  .large-check-table .div {
    color: #202020;
    font-family: "DM Sans-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    left: 0;
    letter-spacing: -0.32px;
    line-height: 28px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .large-check-table .text-wrapper-2 {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 0;
    letter-spacing: -0.28px;
    line-height: 20px;
    position: absolute;
    top: 25px;
    white-space: nowrap;
  }
  
  .large-check-table .ellipse-2 {
    background-color: #32d74b;
    border-radius: 10px;
    height: 20px;
    left: 702px;
    position: absolute;
    top: 25px;
    width: 20px;
  }
  
  .large-check-table .card-2 {
    background-color: #f5f5f5;
    border-radius: 16px;
    height: 70px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 90px;
    width: 734px;
  }
  
  .large-check-table .ellipse-3 {
    background-color: #ff9f0a;
    border-radius: 10px;
    height: 20px;
    left: 702px;
    position: absolute;
    top: 25px;
    width: 20px;
  }
  
  .large-check-table .card-3 {
    background-color: #f5f5f5;
    border-radius: 16px;
    height: 70px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 180px;
    width: 734px;
  }
  
  .large-check-table .ellipse-4 {
    background-color: #ff453a;
    border-radius: 10px;
    height: 20px;
    left: 702px;
    position: absolute;
    top: 25px;
    width: 20px;
  }
  
  .large-check-table .card-4 {
    background-color: #f5f5f5;
    border-radius: 16px;
    height: 70px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 270px;
    width: 734px;
  }
  
  .large-check-table .boton {
    height: 40px;
    left: 644px;
    position: absolute;
    top: 19px;
    width: 114px;
  }
  
  .large-check-table .overlap {
    height: 40px;
    position: relative;
    width: 112px;
  }
  
  .large-check-table .rectangle {
    background-color: #f5f5f5;
    border-radius: 10px;
    height: 39px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 112px;
  }
  
  .large-check-table .text-wrapper-3 {
    color: #202020;
    font-family: "DM Sans-Bold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    left: 0;
    letter-spacing: -0.28px;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 112px;
  }
  

.large-check-table {
  background-color: #ffffff;
  border-radius: 20px;
  height: 488px;
  overflow: hidden;
  position: relative;
  width: 791px;
}

.large-check-table .title {
  height: 32px;
  left: 22px;
  position: absolute;
  top: 23px;
  width: 352px;
}

.large-check-table .div {
  color: #202020;
  font-family: "DM Sans-Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 32px;
  left: 0;
  letter-spacing: -0.48px;
  line-height: 32px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.large-check-table .card {
  height: 102px;
  left: 22px;
  position: absolute;
  top: 101px;
  width: 755px;
}

.large-check-table .overlap-group {
  background-color: #f5f5f5;
  border-radius: 15px;
  height: 102px;
  position: relative;
  width: 751px;
}

.large-check-table .overlap-group-2 {
  height: 79px;
  left: 37px;
  position: absolute;
  top: 12px;
  width: 704px;
}

.large-check-table .direccion-av {
  color: #202020;
  font-family: "DM Sans-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 53px;
  left: 0;
  letter-spacing: -0.28px;
  line-height: 20px;
  position: absolute;
  top: 26px;
  width: 675px;
}

.large-check-table .tag {
  align-items: center;
  display: flex;
  left: 602px;
  position: absolute;
  top: 0;
  width: 102px;
}

.large-check-table .tag-base {
  align-items: center;
  background-color: #32d74b;
  border-radius: 10px;
  display: flex;
  height: 28px;
  justify-content: center;
  overflow: hidden;
  padding: 0px 8px;
  position: relative;
  width: 94px;
}

.large-check-table .text-wrapper-2 {
  color: var(--blackampwhitewhite);
  font-family: "DM Sans-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.large-check-table .p {
  color: #202020;
  font-family: "DM Sans-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  left: 37px;
  letter-spacing: -0.36px;
  line-height: 28px;
  position: absolute;
  top: 12px;
  white-space: nowrap;
  width: 374px;
}

.large-check-table .overlap-wrapper {
  height: 102px;
  left: 22px;
  position: absolute;
  top: 218px;
  width: 755px;
}

.large-check-table .text-wrapper-3 {
  color: var(--blackampwhitewhite);
  font-family: "DM Sans-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.large-check-table .overlap-group-wrapper {
  height: 102px;
  left: 22px;
  position: absolute;
  top: 335px;
  width: 755px;
}

.large-check-table .medium-button-instance {
  background-color: #f5f5f5 !important;
  border-radius: 10px !important;
  height: 34px !important;
  left: 667px !important;
  position: absolute !important;
  top: 22px !important;
  width: 106px !important;
}

.large-check-table .design-component-instance-node {
  left: 35px !important;
  top: 5px !important;
  width: 38px !important;
}

.large-check-table .medium-button-2 {
  color: #202020 !important;
}

.printers {
    background-color: #ffffff;
    border-radius: 20px;
    height: 488px;
    overflow: hidden;
    position: 1300px;
    width: 791px;
  }
  
  .printers .title {
    height: 32px;
    left: 22px;
    position: absolute;
    top: 23px;
    width: 352px;
  }
  
  .printers .div {
    color: #202020;
    font-family: "DM Sans-Bold", Helvetica;
    font-size: 24px;
    font-weight: 700;
    height: 32px;
    left: 0;
    letter-spacing: -0.48px;
    line-height: 32px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .printers .card {
    height: 102px;
    left: 22px;
    position: absolute;
    top: 101px;
    width: 755px;
  }
  
  .printers .overlap-group {
    background-color: #f5f5f5;
    border-radius: 15px;
    height: 102px;
    position: relative;
    width: 751px;
  }
  
  .printers .overlap-group-2 {
    height: 79px;
    left: 37px;
    position: absolute;
    top: 12px;
    width: 704px;
  }
  
  .printers .direccion-av {
    color: #202020;
    font-family: "DM Sans-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    height: 53px;
    left: 0;
    letter-spacing: -0.28px;
    line-height: 20px;
    position: absolute;
    top: 26px;
    width: 675px;
  }
  
  .printers .tag {
    align-items: center;
    display: flex;
    left: 602px;
    position: absolute;
    top: 0;
    width: 102px;
  }
  
  .printers .tag-base {
    align-items: center;
    background-color: #32d74b;
    border-radius: 10px;
    display: flex;
    height: 28px;
    justify-content: center;
    overflow: hidden;
    padding: 0px 8px;
    position: relative;
    width: 94px;
  }
  
  .printers .text-wrapper-2 {
    color: var(--blackampwhitewhite);
    font-family: "DM Sans-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 21px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .printers .p {
    color: #202020;
    font-family: "DM Sans-Bold", Helvetica;
    font-size: 18px;
    font-weight: 700;
    left: 37px;
    letter-spacing: -0.36px;
    line-height: 28px;
    position: absolute;
    top: 12px;
    white-space: nowrap;
    width: 374px;
  }
  
  .printers .overlap-wrapper {
    height: 102px;
    left: 22px;
    position: absolute;
    top: 218px;
    width: 755px;
  }
  
  .printers .text-wrapper-3 {
    color: var(--blackampwhitewhite);
    font-family: "DM Sans-Bold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 21px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .printers .overlap-group-wrapper {
    height: 102px;
    left: 22px;
    position: absolute;
    top: 335px;
    width: 755px;
  }
  
  .printers .medium-button-instance {
    background-color: #f5f5f5 !important;
    border-radius: 10px !important;
    height: 34px !important;
    left: 667px !important;
    position: absolute !important;
    top: 22px !important;
    width: 106px !important;
  }
  
  .printers .design-component-instance-node {
    left: 35px !important;
    top: 5px !important;
    width: 38px !important;
  }
  
  .printers .medium-button-2 {
    color: #202020 !important;
  }

  
  