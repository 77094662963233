.frame {
    align-items: flex-start;
    display: flex;
    gap: 20px;
    height: 1024px;
    padding: 0px 20px;
    position: relative;
    width: 1605px;
  }
  
  .frame .large-dropzone {
    background-color: #ffffff;
    border-radius: 20px;
    height: 1024px;
    overflow: hidden;
    position: relative;
    width: 1010px;
  }
  
  .frame .vista-doc {
    height: 932px;
    left: 165px;
    position: absolute;
    top: 66px;
    width: 680px;
  }
  
  .frame .text-wrapper {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    height: 66px;
    left: 0;
    letter-spacing: -0.36px;
    line-height: 24px;
    position: absolute;
    text-align: center;
    top: -1px;
    width: 1010px;
  }
  
  .frame .fi-bs-angle-left-wrapper {
    height: 932px;
    left: 0;
    position: absolute;
    top: 66px;
    width: 165px;
  }
  
  .frame .icon-instance-node {
    height: 24px !important;
    left: 71px !important;
    position: absolute !important;
    top: 462px !important;
    width: 24px !important;
  }
  
  .frame .fi-bs-angle-right-wrapper {
    height: 932px;
    left: 845px;
    position: absolute;
    top: 66px;
    width: 165px;
  }
  
  .frame .div {
    background-color: #ffffff;
    border-radius: 20px;
    height: 1024px;
    margin-right: -20px;
    overflow: hidden;
    position: relative;
    width: 555px;
  }
  
  .frame .text-wrapper-2 {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    height: 114px;
    left: 26px;
    letter-spacing: -0.36px;
    line-height: 24px;
    position: absolute;
    top: -1px;
    width: 197px;
  }
  
  .frame .text-wrapper-3 {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    height: 114px;
    left: 223px;
    letter-spacing: -0.36px;
    line-height: 24px;
    position: absolute;
    text-align: right;
    top: -1px;
    width: 300px;
  }
  
  .frame .div-2 {
    height: 50px;
    left: 33px;
    position: absolute;
    top: 114px;
    width: 490px;
  }
  
  .frame .overlap-group {
    background-color: #f5f5f5;
    border-radius: 10px;
    height: 50px;
    left: 190px;
    position: absolute;
    top: 0;
    width: 300px;
  }
  
  .frame .fi-rr-angle-small {
    height: 24px !important;
    left: 265px !important;
    position: absolute !important;
    top: 13px !important;
    width: 24px !important;
  }
  
  .frame .fi-rr-print {
    height: 24px !important;
    left: 10px !important;
    position: absolute !important;
    top: 13px !important;
    width: 24px !important;
  }
  
  .frame .p {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    left: 45px;
    letter-spacing: -0.28px;
    line-height: 24px;
    position: absolute;
    top: 12px;
    width: 209px;
  }
  
  .frame .text-wrapper-4 {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    height: 50px;
    left: 0;
    letter-spacing: -0.36px;
    line-height: 24px;
    position: absolute;
    top: -1px;
    width: 190px;
  }
  
  .frame .div-3 {
    height: 50px;
    left: 33px;
    position: absolute;
    top: 617px;
    width: 490px;
  }
  
  .frame .text-wrapper-5 {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    left: 15px;
    letter-spacing: -0.28px;
    line-height: 24px;
    position: absolute;
    top: 12px;
    width: 209px;
  }
  
  .frame .div-4 {
    height: 50px;
    left: 33px;
    position: absolute;
    top: 697px;
    width: 490px;
  }
  
  .frame .text-wrapper-6 {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    height: 50px;
    left: 0;
    letter-spacing: -0.36px;
    line-height: 24px;
    position: absolute;
    top: 70px;
    width: 190px;
  }
  
  .frame .div-5 {
    height: 50px;
    left: 33px;
    position: absolute;
    top: 339px;
    width: 490px;
  }
  
  .frame .div-6 {
    height: 168px;
    left: 33px;
    position: absolute;
    top: 419px;
    width: 490px;
  }
  
  .frame .group {
    height: 168px;
    left: 190px;
    position: absolute;
    top: 0;
    width: 308px;
  }
  
  .frame .overlap-group-2 {
    background-color: #f5f5f5;
    border-radius: 10px;
    height: 168px;
    position: relative;
    width: 300px;
  }
  
  .frame .vector {
    height: 1px;
    left: 8px;
    object-fit: cover;
    position: absolute;
    top: 48px;
    width: 284px;
  }
  
  .frame .text-wrapper-7 {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    left: 15px;
    letter-spacing: -0.28px;
    line-height: 24px;
    position: absolute;
    top: 13px;
    width: 242px;
  }
  
  .frame .text-wrapper-8 {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    left: 15px;
    letter-spacing: -0.28px;
    line-height: 24px;
    position: absolute;
    top: 60px;
    width: 269px;
  }
  
  .frame .text-wrapper-9 {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    left: 15px;
    letter-spacing: -0.28px;
    line-height: 24px;
    position: absolute;
    top: 94px;
    width: 269px;
  }
  
  .frame .text-wrapper-10 {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    left: 15px;
    letter-spacing: -0.28px;
    line-height: 24px;
    position: absolute;
    top: 128px;
    width: 269px;
  }
  
  .frame .div-7 {
    height: 115px;
    left: 33px;
    position: absolute;
    top: 194px;
    width: 490px;
  }
  
  .frame .overlap-group-wrapper {
    height: 50px;
    left: 190px;
    position: absolute;
    top: 0;
    width: 302px;
  }
  
  .frame .overlap-group-3 {
    background-color: #f5f5f5;
    border-radius: 10px;
    height: 50px;
    position: relative;
    width: 300px;
  }
  
  .frame .text-wrapper-11 {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    left: 15px;
    letter-spacing: -0.28px;
    line-height: 24px;
    position: absolute;
    top: 13px;
    width: 236px;
  }
  
  .frame .overlap-wrapper {
    height: 50px;
    left: 190px;
    position: absolute;
    top: 65px;
    width: 302px;
  }
  
  .frame .text-wrapper-12 {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    left: 15px;
    letter-spacing: -0.28px;
    line-height: 24px;
    position: absolute;
    top: 13px;
    width: 250px;
  }
  
  .frame .boton {
    height: 49px;
    left: 283px;
    position: absolute;
    top: 954px;
    width: 242px;
  }
  
  .frame .overlap {
    height: 49px;
    position: relative;
    width: 240px;
  }
  
  .frame .rectangle {
    background-color: #0f81fe;
    border-radius: 10px;
    height: 48px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 240px;
  }
  
  .frame .text-wrapper-13 {
    color: #ffffff;
    font-family: "DM Sans-Bold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    height: 49px;
    left: 0;
    letter-spacing: -0.28px;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 240px;
  }
  
  .frame .div-wrapper {
    height: 49px;
    left: 33px;
    position: absolute;
    top: 954px;
    width: 242px;
  }
  
  .frame .rectangle-2 {
    background-color: #f5f5f5;
    border-radius: 10px;
    height: 48px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 240px;
  }
  
  .frame .text-wrapper-14 {
    color: #202020;
    font-family: "DM Sans-Bold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    height: 49px;
    left: 0;
    letter-spacing: -0.28px;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 240px;
  }
  
  .frame .fi-sr-checkbox {
    height: 24px !important;
    left: 499px !important;
    position: absolute !important;
    top: 781px !important;
    width: 24px !important;
  }

  .side-bar {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
    height: 1152px;
    position: relative;
  }
  
  .side-bar .sidebar-notification {
    background-color: var(--secondary-primary-white);
    height: 1152px;
    position: relative;
    width: 290px;
  }
  
  .side-bar .frame {
    height: 130px;
    left: 0;
    position: absolute;
    top: 0;
    width: 290px;
  }
  
  .side-bar .separator {
    
    background-position: 50% 50%;
    background-size: cover;
    height: 1px;
    left: 0;
    position: absolute;
    top: 129px;
    width: 290px;
  }
  
  .side-bar .LOGO {
    height: 36px;
    left: 54px;
    position: absolute;
    top: 50px;
    width: 181px;
  }
  
  .side-bar .div {
    height: 70px;
    left: 0;
    position: absolute;
    top: 130px;
    width: 290px;
  }
  
  .side-bar .separator-2 {
 
    background-position: 50% 50%;
    background-size: cover;
    height: 1px;
    left: 0;
    position: absolute;
    top: 69px;
    width: 290px;
  }
  
  .side-bar .fi-bs-check-wrapper {
    height: 37px;
    left: 226px;
    position: absolute;
    top: 16px;
    width: 37px;
  }
  
  .side-bar .fi-bs-check {
    height: 18px !important;
    left: 10px !important;
    position: absolute !important;
    top: 9px !important;
    width: 18px !important;
  }
  
  .side-bar .div-wrapper {
    height: 37px;
    left: 24px;
    position: absolute;
    top: 16px;
    width: 202px;
  }
  
  .side-bar .text-wrapper {
    color: #000000;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    height: 37px;
    left: 0;
    letter-spacing: -0.28px;
    line-height: 24px;
    position: absolute;
    top: -1px;
    width: 202px;
  }
  
  .side-bar .vista-doc-mini-list {
    height: 253px;
    left: 0;
    position: absolute;
    top: 200px;
    width: 290px;
  }
  
  