.large-check-table {
    background-color: var(--secondary-primary-white);
    border-radius: 30px;
    height: 949px;
    overflow: hidden;
    position: relative;
    width: 1590px;
  }
  
  .large-check-table .title {
    height: 32px;
    left: 31px;
    position: absolute;
    top: 23px;
    width: 238px;
  }
  
  .large-check-table .text-wrapper {
    color: #202020;
    font-family: "DM Sans-Bold", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 0;
    letter-spacing: -0.48px;
    line-height: 32px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .large-check-table .content {
    height: 372px;
    left: 28px;
    position: absolute;
    top: 95px;
    width: 1535px;
  }
  
  .large-check-table .project {
    background-color: #f5f5f5;
    border-radius: 15px;
    height: 372px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 755px;
  }
  
  .large-check-table .text {
    height: 16px;
    left: 228px;
    position: absolute;
    top: 67px;
    width: 240px;
  }
  
  .large-check-table .div {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 0;
    letter-spacing: -0.32px;
    line-height: 16px;
    position: absolute;
    top: 0;
    width: 238px;
  }
  
  .large-check-table .div-wrapper {
    height: 16px;
    left: 228px;
    position: absolute;
    top: 97px;
    width: 240px;
  }
  
  .large-check-table .text-2 {
    height: 16px;
    left: 228px;
    position: absolute;
    top: 127px;
    width: 240px;
  }
  
  .large-check-table .image {
    height: 150px;
    left: 33px;
    position: absolute;
    top: 34px;
    width: 150px;
  }
  
  .large-check-table .group {
    height: 113px;
    left: 516px;
    position: absolute;
    top: 21px;
    width: 221px;
  }
  
  .large-check-table .boton {
    height: 30px;
    left: 121px;
    position: absolute;
    top: 0;
    width: 102px;
  }
  
  .large-check-table .overlap-group {
    height: 30px;
    position: relative;
    width: 100px;
  }
  
  .large-check-table .rectangle {
    background-color: #32d74b;
    border-radius: 10px;
    height: 29px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100px;
  }
  
  .large-check-table .text-wrapper-2 {
    color: #ffffff;
    font-family: "DM Sans-Bold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    height: 30px;
    left: 0;
    letter-spacing: -0.28px;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100px;
  }
  
  .large-check-table .overlap-wrapper {
    height: 76px;
    left: 0;
    position: absolute;
    top: 37px;
    width: 225px;
  }
  
  .large-check-table .overlap {
    height: 76px;
    position: relative;
    width: 221px;
  }
  
  .large-check-table .rectangle-2 {
    background-color: #ffffff;
    border-radius: 10px;
    height: 75px;
    left: 0;
    position: absolute;
    top: 0;
    width: 221px;
  }
  
  .large-check-table .vector {
    height: 1px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 38px;
    width: 220px;
  }
  
  .large-check-table .text-wrapper-3 {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    height: 39px;
    left: 13px;
    letter-spacing: -0.28px;
    line-height: 20px;
    position: absolute;
    top: 0;
    width: 196px;
  }
  
  .large-check-table .text-wrapper-4 {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    height: 39px;
    left: 13px;
    letter-spacing: -0.28px;
    line-height: 20px;
    position: absolute;
    top: 37px;
    width: 196px;
  }
  
  .large-check-table .frame {
    height: 50px;
    left: 25px;
    position: absolute;
    top: 203px;
    width: 706px;
  }
  
  .large-check-table .text-wrapper-5 {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 600px;
    letter-spacing: -0.32px;
    line-height: 16px;
    position: absolute;
    top: 18px;
    width: 107px;
  }
  
  .large-check-table .text-wrapper-6 {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    height: 50px;
    left: 0;
    letter-spacing: -0.36px;
    line-height: 24px;
    position: absolute;
    top: -1px;
    width: 190px;
  }
  
  .large-check-table .department {
    background-color: var(--secondary-primary-white);
    border-radius: 16px;
    height: 91px;
    left: 398px;
    overflow: hidden;
    position: absolute;
    top: 254px;
    width: 333px;
  }
  
  .large-check-table .p {
    color: #202020;
    font-family: "DM Sans-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    height: 20px;
    left: 22px;
    letter-spacing: -0.32px;
    line-height: 20px;
    position: absolute;
    top: 16px;
    white-space: nowrap;
  }
  
  .large-check-table .text-wrapper-7 {
    color: #808085;
    font-family: "DM Sans-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    height: 14px;
    left: 22px;
    letter-spacing: -0.28px;
    line-height: 14px;
    position: absolute;
    top: 40px;
    white-space: nowrap;
  }
  
  .large-check-table .text-wrapper-8 {
    color: #808085;
    font-family: "DM Sans-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    height: 14px;
    left: 22px;
    letter-spacing: -0.28px;
    line-height: 14px;
    position: absolute;
    top: 60px;
    white-space: nowrap;
  }
  
  .large-check-table .img {
    height: 18px;
    left: 293px;
    position: absolute;
    top: 36px;
    width: 18px;
  }
  
  .large-check-table .department-2 {
    background-color: var(--secondary-primary-white);
    border-radius: 16px;
    height: 91px;
    left: 25px;
    overflow: hidden;
    position: absolute;
    top: 254px;
    width: 334px;
  }
  
  .large-check-table .vector-2 {
    height: 18px;
    left: 294px;
    position: absolute;
    top: 36px;
    width: 18px;
  }
  
  .large-check-table .project-2 {
    background-color: #f5f5f5;
    border-radius: 15px;
    height: 162px;
    left: 780px;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 755px;
  }
  
  .large-check-table .text-3 {
    height: 16px;
    left: 166px;
    position: absolute;
    top: 33px;
    width: 266px;
  }
  
  .large-check-table .text-wrapper-9 {
    color: #202020;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 0;
    letter-spacing: -0.32px;
    line-height: 16px;
    position: absolute;
    top: 0;
    width: 264px;
  }
  
  .large-check-table .text-4 {
    height: 16px;
    left: 166px;
    position: absolute;
    top: 63px;
    width: 266px;
  }
  
  .large-check-table .text-5 {
    height: 16px;
    left: 166px;
    position: absolute;
    top: 93px;
    width: 266px;
  }
  
  .large-check-table .text-6 {
    height: 16px;
    left: 166px;
    position: absolute;
    top: 123px;
    width: 266px;
  }
  
  .large-check-table .image-2 {
    height: 100px;
    left: 33px;
    position: absolute;
    top: 24px;
    width: 100px;
  }
  
  .large-check-table .overlap-group-wrapper {
    height: 30px;
    left: 637px;
    position: absolute;
    top: 115px;
    width: 102px;
  }
  
  .large-check-table .text-wrapper-10 {
    color: #f5f5f5;
    font-family: "DM Sans-Bold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    height: 30px;
    left: 0;
    letter-spacing: -0.28px;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100px;
  }
  
  .large-check-table .project-3 {
    height: 162px;
    left: 780px;
    object-fit: cover;
    position: absolute;
    top: 210px;
    width: 755px;
  }
  
  .large-check-table .more-button {
    height: 40px;
    left: 1377px;
    position: absolute;
    top: 19px;
    width: 188px;
  }
  
  .large-check-table .overlap-2 {
    background-color: #f5f5f5;
    border-radius: 10px;
    height: 40px;
    position: relative;
    width: 186px;
  }
  
  .large-check-table .text-wrapper-11 {
    color: #000000;
    font-family: "DM Sans-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    left: 10px;
    letter-spacing: -0.28px;
    line-height: 20px;
    position: absolute;
    top: 9px;
    white-space: nowrap;
  }
  
  .large-check-table .add-circle {
    height: 26px;
    left: 152px;
    position: absolute;
    top: 8px;
    width: 26px;
  }
  