.drop-container:hover{
    transform: scale(1.01);
    cursor: pointer;
    border-color: #f045ca;
    background-color: #4e0f9c0a;
}

.title-dropdown-custom{
    color: #202020;
    text-align: center;    
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    letter-spacing: -0.4px;
}