.large-dropzone {
  background: linear-gradient(180deg, rgb(233, 233, 233) 0%, rgb(234, 234, 234) 100%);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  height: 382px;
  overflow: hidden;
  position: relative;
}

.large-dropzone .overlap-group {
  /* background-image: url(./rectangle-436.png); */
  background-size: 100% 100%;
  height: 382px;
  left: 758px;
  position: absolute;
  top: 0;
  width: 832px;
}

.large-dropzone .frame {
  background: linear-gradient(180deg, rgb(233, 233, 233) 0%, rgb(241, 241, 241) 100%);
  height: 245px;
  left: 23px;
  position: absolute;
  top: 62px;
  width: 392px;
}

.large-dropzone .body {
  height: 100px;
  left: 0;
  position: absolute;
  top: 49px;
  width: 432px;
}

.large-dropzone .text-wrapper {
  color: #202020;
  font-family: "DM Sans-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.48px;
  line-height: 28px;
  position: absolute;
  top: 0;
  width: 430px;
}

.large-dropzone .title {
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 432px;
}

.large-dropzone .div {
  color: #202020;
  font-family: "DM Sans-Bold", Helvetica;
  font-size: 36px;
  font-weight: 700;
  left: 0;
  letter-spacing: -0.72px;
  line-height: 32px;
  position: absolute;
  top: 0;
  width: 430px;
}

.large-dropzone .rectangle {
  height: 50px;
  left: 23px;
  object-fit: cover;
  position: absolute;
  top: 299px;
  width: 164px;
}

.large-dropzone .img {
  height: 50px;
  left: 223px;
  object-fit: cover;
  position: absolute;
  top: 299px;
  width: 164px;
}

.large-dropzone .content-wrapper {
  background-color: #ffffff;
  border: 1px dashed;
  border-color: #f5f7f6;
  border-radius: 13px;
  height: 315px;
  left: 27px;
  position: absolute;
  top: 34px;
  width: 707px;
}

.large-dropzone .content {
  height: 151px;
  left: 209px;
  position: relative;
  top: 81px;
  width: 291px;
}

.large-dropzone .text-wrapper-2 {
  color: #202020;
  font-family: var(--body-text-extra-large-bold-font-family);
  font-size: var(--body-text-extra-large-bold-font-size);
  font-style: var(--body-text-extra-large-bold-font-style);
  font-weight: var(--body-text-extra-large-bold-font-weight);
  left: 47px;
  letter-spacing: var(--body-text-extra-large-bold-letter-spacing);
  line-height: var(--body-text-extra-large-bold-line-height);
  position: absolute;
  text-align: center;
  top: 92px;
  white-space: nowrap;
  width: 193px;
}

.large-dropzone .p {
  color: #202020bf;
  font-family: var(--body-text-extra-small-medium-font-family);
  font-size: var(--body-text-extra-small-medium-font-size);
  font-style: var(--body-text-extra-small-medium-font-style);
  font-weight: var(--body-text-extra-small-medium-font-weight);
  left: 0;
  letter-spacing: var(--body-text-extra-small-medium-letter-spacing);
  line-height: var(--body-text-extra-small-medium-line-height);
  position: absolute;
  text-align: center;
  top: 131px;
  white-space: nowrap;
}

.large-dropzone .upload-instance {
  height: 80px !important;
  left: 104px !important;
  position: absolute !important;
  top: 0 !important;
  width: 80px !important;
}



/*----------------------------------------------------------------------------------------------------------------------------------------------------------------------*/




.dropzone-container{
  border-radius: 20px;
  background: rgb(233,233,233);
  background: linear-gradient(180deg, rgba(233,233,233,1) 0%, rgba(240,240,240,1) 100%);
  display: flex;
  min-height: 35vh;
}



.overlap-group {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
}

.dropzone-container .content{
  border-radius: 13px;
  border: 1px dashed #a7a7a7;
  background: #FFF;
  cursor: pointer;
}


.dropzone-container .text-wrapper-2{
  color: #202020;
  text-align: center;
  /* Body Text/ Extra-Large/ Bold */
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 160% */
  letter-spacing: -0.4px;
}


.dropzone-container .content:hover{
  transform: scale(1.01);
}


.dropzone-description{
  color: rgba(32, 32, 32, 0.75);
  text-align: center;
  /* Body Text/ Extra-Small/ Medium */
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
}



.overlap-group .title{
  color: #202020;
  font-family: DM Sans;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 88.889% */
  letter-spacing: -0.72px;
}




.overlap-group .text-wrapper{
  color: #202020;
  font-family: DM Sans;
  font-size: 1.45rem;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 116.667% */
  letter-spacing: -0.48px;
}


.card {
    border-radius: 20px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    max-height: 52vh;
}



.card-header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0rem;
}

.card-body {
    overflow-y: auto;
}

.headquarters-list {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
}

.headquarters-item {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 1rem;
    border-radius: 16px;
    background: #F5F5F5;
    height: 70px;
    padding: 0.5rem;
    align-items: center;
    
}

.headquarters-item:hover {
  transform: scale(1.005);
  cursor: pointer;
}

.item-overlap .title {
  color: #202020;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 175% */
  letter-spacing: -0.32px;
}
.item-overlap .subtitle {
  color: rgba(32, 32, 32, 0.50);
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
}
.button{
  border-radius: 10px;
  background: #F5F5F5;
  padding: 0.5rem;
  cursor: pointer;
}

.button-primary{  
  background: #0F81FE;
}

.button span{
  text-align: center;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
}

.button:hover{
  transform: scale(1.05);
}


.card-header .title{
  color: #202020;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.48px;
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}
